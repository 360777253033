<template>
  <v-combobox
    v-bind="$attrs"
    v-model="pValue"
    :items="icons"
    :prepend-icon="value"
    :color="$attrs.dark || $attrs.dark === '' ? 'white' : 'accent'"
    clearable
    cache-items
  >
    <template v-slot:append-outer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="grey" size="18" dark v-bind="attrs" v-on="on">
            mdi-help
          </v-icon>
        </template>
        <span>{{ helpText }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item="{ on, attrs, item }">
      <v-list-item ripple v-bind="attrs" v-on="on">
        <v-list-item-action>
          <v-icon color="primary"> {{ item }} </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ item }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
  </v-combobox>
</template>



<script>
import { DefaultIcons } from "../../../molecules/constants/iconsList";

export default {
  props: {
    value: {
      default: "",
    },
    helpText: {
      type: String,
      default: "",
 
    },
  },
  data() {
    return {
      icons: DefaultIcons,
      pValue: this.value,
    };
  },
  watch: {
    value(newVal) {
      this.pValue = newVal;
    },
    pValue(){
      this.$emit('input', this.pValue);
    }
  },
};
</script>


<style lang="scss">
</style>