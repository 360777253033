<template>
  <v-form ref="form" v-model="pValid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" md="6" sm="6" class="new-service-col">
        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="serviceNameRules"
          label="Tag Name"
          :helpText="'Provide a name of the tag. Please make sure that it is unique across the system'"
          required
        ></HelpFormInputVue>

        <SelectorIcons
          dark
          v-model="form.icon"
          label="Tag Icon"
          :helpText="'You can specify custom icon for the tag'"
        >
        </SelectorIcons>

        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-color-picker
              v-model="color"
              flat
              class="color-picker"
              :canvas-height="80"
            ></v-color-picker>
          </v-col>
        </v-row>

        <FormSection
          :label="'Preview'"
          class="mt-0"
          :icon="'mdi-eye-outline'"
          :editable="false"
          underline
          right
        ></FormSection>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-chip
              v-if="form.name"
              class="ma-2 preview"
              :color="form.color"
              label
              text-color="white"
            >
              <v-icon v-if="form.icon" left> {{ form.icon }} </v-icon>
              {{ form.name }}
            </v-chip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
<script>
import SelectorIcons from "../../../../atoms/common/autocompletes/SelectorIcons.vue";
import FormSection from "../../../../atoms/common/FormSection.vue";
import HelpFormInputVue from "../../../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {},
    valid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Form
      pValid: this.valid,
      form: {
        ...this.value,
      },
      color: {
        hex: this.value.color ? this.value.color : "",
      },

      // ======rules
      serviceNameRules: [(v) => !!v || "Tag Name is required"],
    };
  },
  components: {
    HelpFormInputVue,
    FormSection,
    SelectorIcons,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    color: {
      handler(newVal) {
        this.form.color = newVal.hexa;

        this.$emit("input", this.form);
      },
      deep: true,
    },
    pValid(newVal) {
      this.$emit("onValid", newVal);
    },
  },
};
</script>
    
    
    <style lang="scss" scoped>
.new-service-col {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  flex-grow: 1;
  max-width: 100%;
}

.color-picker {
  max-width: 100%;
  background: transparent;
}

.preview {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-chip__content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>